// import { Spin } from "antd"
import React from "react"
import { useRoutes } from "react-router-dom"
import ROUTER from "./index"
import { BallTriangle } from "react-loader-spinner"
import Contribute from "src/pages/ANONYMOUS/Contribute"

// ANONYMOUS

const PublicRouters = React.lazy(() => import("src/pages/PublicRouters"))
const SvgViewer = React.lazy(() => import("src/pages/SvgViewer"))
const NotFound = React.lazy(() => import("src/pages/NotFound"))
const Home = React.lazy(() => import("src/pages/ANONYMOUS/Home"))
const LoginPage = React.lazy(() => import("src/pages/ANONYMOUS/LoginPage"))
const RegisterPage = React.lazy(() => import("src/pages/ANONYMOUS/Register"))
const InfoAccount = React.lazy(() => import("src/pages/ANONYMOUS/InfoAccount"))
const Policy = React.lazy(() => import("src/pages/ANONYMOUS/Policy"))
const ViewPdf = React.lazy(() => import("src/pages/ANONYMOUS/ViewPdf"))
const ViewPolicy = React.lazy(() => import("src/pages/ANONYMOUS/ViewPolicy"))

// USER
const PrivateRoutes = React.lazy(() => import("src/pages/PrivateRoutes"))
const ChangePassword = React.lazy(() => import("src/pages/USER/ChangePassword"))

// ADMIN
const InfoUser = React.lazy(() => import("src/pages/ADMIN/InfoUser"))

const AminRoutes = React.lazy(() => import("src/pages/ADMIN/AminRoutes"))
const Statistical = React.lazy(() => import("src/pages/ADMIN/Statistical"))
const ConfigurationFuncition = React.lazy(() =>
  import("src/pages/ADMIN/ConfigurationFuncition"),
)
const Domain = React.lazy(() => import("src/pages/ADMIN/Domain"))

function LazyLoadingComponent({ children }) {
  return (
    <React.Suspense
      fallback={
        <div className="loading-center" style={{ height: "100vh" }}>
          {/* <Spin /> */}
          <BallTriangle
            height={50}
            width={50}
            radius={5}
            color="#01638d"
            ariaLabel="ball-triangle-loading"
            wrapperClass={{}}
            wrapperStyle=""
            visible={true}
          />
        </div>
      }
    >
      {children}
    </React.Suspense>
  )
}

const routes = [
  {
    path: ROUTER.SVG_VIEWER,
    element: (
      <LazyLoadingComponent>
        <SvgViewer />
      </LazyLoadingComponent>
    ),
  },
  // ADMIN
  {
    element: (
      <LazyLoadingComponent>
        <AminRoutes />
      </LazyLoadingComponent>
    ),
    children: [
      {
        path: ROUTER.INFO_USER,
        element: (
          <LazyLoadingComponent>
            <InfoUser />
          </LazyLoadingComponent>
        ),
      },
      {
        path: ROUTER.THONG_KE,
        element: (
          <LazyLoadingComponent>
            <Statistical />
          </LazyLoadingComponent>
        ),
      },
      {
        path: ROUTER.LIST_DOMAIN,
        element: (
          <LazyLoadingComponent>
            <Domain />
          </LazyLoadingComponent>
        ),
      },
      {
        path: ROUTER.CHUC_NANG,
        element: (
          <LazyLoadingComponent>
            <ConfigurationFuncition />
          </LazyLoadingComponent>
        ),
      },
    ],
  },
  //  USER
  {
    element: (
      <LazyLoadingComponent>
        <PrivateRoutes />
      </LazyLoadingComponent>
    ),
    children: [
      {
        path: ROUTER.DOI_MAT_KHAU,
        element: (
          <LazyLoadingComponent>
            <ChangePassword />
          </LazyLoadingComponent>
        ),
      },
    ],
  },

  //  ANONYMOUS
  {
    element: (
      <LazyLoadingComponent>
        <PublicRouters />
      </LazyLoadingComponent>
    ),
    children: [
      {
        path: ROUTER.HOME,
        element: (
          <LazyLoadingComponent>
            <Home />
          </LazyLoadingComponent>
        ),
      },
      {
        path: ROUTER.POLICY,
        element: (
          <LazyLoadingComponent>
            <Policy />
          </LazyLoadingComponent>
        ),
      },
      {
        path: ROUTER.VIEW_POLICY,
        element: (
          <LazyLoadingComponent>
            <ViewPolicy />
          </LazyLoadingComponent>
        ),
      },
      {
        path: ROUTER.DANG_NHAP,
        element: (
          <LazyLoadingComponent>
            <LoginPage />
          </LazyLoadingComponent>
        ),
      },
      {
        path: ROUTER.INSTRUCT,
        element: (
          <LazyLoadingComponent>
            <ViewPdf />
          </LazyLoadingComponent>
        ),
      },
      {
        path: ROUTER.DANG_KY,
        element: (
          <LazyLoadingComponent>
            <RegisterPage />
          </LazyLoadingComponent>
        ),
      },
      {
        path: ROUTER.ACCOUNT,
        element: (
          <LazyLoadingComponent>
            <InfoAccount />
          </LazyLoadingComponent>
        ),
      },
      {
        path: ROUTER.CONTRIBUTE,
        element: (
          <LazyLoadingComponent>
            <Contribute />
          </LazyLoadingComponent>
        ),
      },
    ],
  },
  {
    path: "*",
    element: (
      <LazyLoadingComponent>
        <NotFound />
      </LazyLoadingComponent>
    ),
  },
]
const AppRouter = () => {
  const renderRouter = useRoutes(routes)
  return renderRouter
}
export default AppRouter
