import axios from "axios"
import http from "../index"
import { apiCreateContribute } from "./urls"
import QueryString from "qs"

const createContribute = body => http.post(apiCreateContribute, body)

const ContributeService = {
  createContribute,
}
export default ContributeService
